import React from 'react'
import styled from 'react-emotion'

const Ls = ({ className }) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='50'
    height='50'
    viewBox='0 -30 331.9 331.8'
    className={className}
  >
    <g>
      <path d='m76,44.6l56.1,0l-34.1,193.7l95,0l-8.6,48.9l-150.9,0l42.5,-242.6z' />
      <path d='M215.8,284.3c-7.3-15.8-14.1-30.5-21.4-46.2c4.2,0,7.4,0.2,10.7-0.1c4.3-0.4,8.6-0.9,12.8-1.8c2.4-0.5,4.7-1.8,6.7-3.2
		c8.2-5.8,8.5-16.7,0.1-22.2c-4.6-3.1-10.1-4.9-15.4-7c-8.7-3.4-17.7-5.9-26.1-10c-26.2-12.9-34.3-40.7-19.7-66.1
		c7-12.2,17.5-20.4,30.3-25.7c27.2-11.3,54.5-11.2,81.6,0.3c7.6,3.2,14.3,7.8,20.1,13.8c1.1,1.2,2.1,2.5,3.6,4.2
		c-11.3,9.7-22.4,19.1-33.8,28.8c-1.2-1.1-2.1-1.9-3-2.8c-11.9-12-26.3-14.9-42.3-11.4c-4,0.9-7.5,2.7-10.2,5.9
		c-4.9,5.7-4.2,13.9,2.1,17.8c3.9,2.4,8.4,4,12.7,5.6c7.1,2.5,14.3,4.4,21.3,7.1c4.8,1.8,9.5,4.2,13.8,6.9
		c17.5,10.7,23.8,26.8,21.4,46.5c-3.3,26.9-18.7,44.6-43.7,53.9C230.7,281,223.4,282.3,215.8,284.3z' />
    </g>
  </svg>
)

export default styled(Ls)`
  fill: ${props => (props.dark ? props.theme.brand.primary : props.theme.brand.bodyColor)};
  margin: 1rem 0rem;
`
